import React from 'react';
import styled from 'styled-components';
import { Alert as GrommetAlert } from 'grommet-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { Heading } from '../../pulsechain_components/Heading';
import { Text } from '../../pulsechain_components/Text';
import { Link } from '../../pulsechain_components/Link';
import { Alert } from '../../pulsechain_components/Alert';
import { Code } from '../../pulsechain_components/Code';
import { NETWORK_NAME, TRANSLATE_CLI_FLAGS } from '../../utils/envVars';
import { Button } from '../../pulsechain_components/Button';
import { colors } from '../../styles/styledComponentsTheme';
import useIntlNetworkName from '../../hooks/useIntlNetworkName';

const AlertIcon = styled(p => <GrommetAlert {...p} />)`
  display: block;
  margin: 1.3rem;
`;

const Pre = styled.pre`
  white-space: normal;
`;

const Card = styled.div`
  padding: 20px;
  border: 1px solid #323234;
  border-radius: 12px;
  width: 100%;
  margin: 16px;
  background: #202022;
  @media only screen and (max-width: ${p => p.theme.screenSizes.medium}) {
    margin: 0px;
    margin-top: 16px;
  }
`;

export const Option1 = ({
  validatorCount,
  withdrawalAddress,
  os,
}: {
  validatorCount: number | string;
  withdrawalAddress: string;
  os: string;
}) => {
  const { formatMessage } = useIntl();
  const { consensusLayerName } = useIntlNetworkName();

  return (
    <div className="mt30">
      <Heading level={2} size="small" color="blueMedium" className="mb20">
        <FormattedMessage defaultMessage="Download command line app" />
      </Heading>
      <Text weight={500}>
        <FormattedMessage defaultMessage="Step 1: Download the deposit command line interface app for your operating system" />
      </Text>
      <Link
        isTextLink={false}
        to="https://gitlab.com/pulsechaincom/staking-deposit-cli/"
        className="my40"
      >
        <Button
          className="flex"
          rainbow
          label={formatMessage({ defaultMessage: 'Download from GitLab' })}
        />
      </Link>

      <Alert variant="warning" className="my40">
        <div className="flex">
          <AlertIcon />
          <Text
            weight={500}
            color="yellowDarkest"
            className="my10"
            style={{ wordBreak: 'break-word' }}
          >
            <FormattedMessage
              defaultMessage="Please make sure that you are downloading from the official PulseChain GitLab account by verifying the url: {url}"
              values={{
                url: (
                  <strong>
                    https://gitlab.com/pulsechaincom/staking-deposit-cli
                  </strong>
                ),
              }}
              description="{url} is link to GitLab CLI release, made bold for emphasis"
            />
          </Text>
        </div>
      </Alert>

      <Text weight={500} className="mt20">
        <FormattedMessage defaultMessage="Step 2: Generate deposit keys using the Ethereum Foundation deposit tool" />
      </Text>
      <Alert className="my20" variant="info">
        <FormattedMessage defaultMessage="For security, we recommend you disconnect from the internet to complete this step." />
      </Alert>
      <ul>
        <li>
          <FormattedMessage defaultMessage="Decompress the file you just downloaded" />
        </li>
        <li>
          <FormattedMessage
            defaultMessage="Use the terminal to move into the directory that contains the {deposit} executable"
            values={{
              deposit: (
                <code>
                  <FormattedMessage defaultMessage="deposit" />
                </code>
              ),
            }}
            description="{deposit} = 'deposit' styled as code"
          />
        </li>
        <li>
          <FormattedMessage defaultMessage="Run the following command to launch the app" />
        </li>
        <Alert variant="secondary" className="my10">
          <Pre className="my10">
            {(os === 'linux' || os === 'mac') && (
              <span style={{ color: colors.red.medium }}>./deposit </span>
            )}
            {os === 'windows' && (
              <>
                <span style={{ color: colors.red.medium }}>.\deposit</span>
                <span style={{ color: colors.purple.dark }}>.exe </span>
              </>
            )}
            <span style={{ color: colors.red.medium }}>new-mnemonic</span>
            <span style={{ color: colors.red.medium }}>
              {validatorCount > 0
                ? ` --${formatMessage({
                    defaultMessage: 'num_validators',
                    description:
                      'this is used as a command line flag, short for "number of validators"',
                  })} ${validatorCount}`
                : ''}{' '}
            </span>
            <span style={{ color: colors.red.medium }}>
              {`--${formatMessage({
                defaultMessage: 'chain',
                description: 'this is used as a command line flag',
              })} ${NETWORK_NAME.toLowerCase()
                .split(' ')
                .join('-')}`}{' '}
              {withdrawalAddress &&
                `--${
                  TRANSLATE_CLI_FLAGS
                    ? formatMessage({
                        defaultMessage: 'eth1_withdrawal_address',
                        description: 'this is used as a command line flag',
                      })
                    : 'eth1_withdrawal_address'
                } ${withdrawalAddress}`}
            </span>
          </Pre>
        </Alert>
        <Card className="my10">
          <div>
            <Text>
              <FormattedMessage
                defaultMessage="Please make sure you have set {flag} for {consensusLayerName}, otherwise the deposit will be invalid."
                values={{
                  flag: (
                    <Code>
                      {`--${formatMessage({
                        defaultMessage: 'chain',
                        description: 'this is used as a command line flag',
                      })} ${NETWORK_NAME.toLowerCase()
                        .split(' ')
                        .join('-')}`}
                    </Code>
                  ),
                  consensusLayerName,
                }}
                description="{flag} is a terminal command styled as code."
              />
            </Text>
          </div>
        </Card>
        <li>
          <FormattedMessage defaultMessage="Now follow the instructions presented to you in the terminal window to generate your keys." />
        </li>
      </ul>
    </div>
  );
};
