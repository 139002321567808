import React from 'react';
import styled from 'styled-components';
import { Box } from 'grommet';
import { FormattedMessage } from 'react-intl';
import { Link } from '../../pulsechain_components/Link';
import { Paper } from '../../pulsechain_components/Paper';
import { Heading } from '../../pulsechain_components/Heading';
import { Text } from '../../pulsechain_components/Text';
import { ImageSelectionBox } from '../../pulsechain_components/ImageSelectionBox';
import { Client } from './index';
import { ClientId } from '../../store/actions/clientActions';

const Card = styled.div`
  padding: 20px;
  border: 1px solid #323234;
  border-radius: 12px;
  width: 100%;
  margin: 16px;
  background: #202022;
  @media only screen and (max-width: ${p => p.theme.screenSizes.medium}) {
    margin: 0px;
    margin-top: 16px;
  }
`;

const ClientOptionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
`;

const ClientDescriptionContainer = styled.div`
  width: 100%;
  border-radius: 4px;
  background: #202022;
  border: 1px solid #ececec;
  margin-top: 30px;
  padding: 1rem 2rem;
`;

type Props = {
  title?: string;
  clients: Array<Client>;
  currentClient: ClientId;
  setCurrentClient: (client: ClientId) => void;
  clientDetails: any;
  ethClientStep: string;
};

const SelectClientSection = ({
  title,
  clients,
  currentClient,
  setCurrentClient,
  clientDetails,
  ethClientStep,
}: Props): JSX.Element => (
  <Paper>
    <Heading level={3} size="small" color="greenLight" className="mb20">
      {title}
    </Heading>
    {ethClientStep === 'execution' && (
      <div style={{ paddingBottom: '1rem' }}>
        <FormattedMessage defaultMessage="To process incoming validator deposits from the execution layer, you'll need to run an execution client as well as your consensus client." />
      </div>
    )}
    <Box className="flex flex-column space-between mt10">
      <ClientOptionContainer>
        {clients.map(({ clientId, name, imgUrl, language }) => {
          const inputId = `${clientId}-client`;
          const onClick = () => setCurrentClient(clientId);

          return (
            <ImageSelectionBox
              fullWidthImg
              key={inputId}
              src={imgUrl}
              isActive={currentClient === clientId}
              onClick={onClick}
              text={name}
              language={language}
            />
          );
        })}
      </ClientOptionContainer>
      <ClientDescriptionContainer>
        {clientDetails[currentClient]}
      </ClientDescriptionContainer>
      <Card className="mt30 mb20">
        <div>
          <Heading level={4} className="mb10">
            <FormattedMessage defaultMessage="Remember" />
          </Heading>
          <Text className="my10">
            <FormattedMessage defaultMessage="After client installation, ensure you are fully synced before submitting your staking deposit. This can take several days." />{' '}
            <Link primary inline shouldOpenNewTab to="/checklist">
              <FormattedMessage defaultMessage="See more in the validator checklist" />
            </Link>
          </Text>
        </div>
      </Card>
    </Box>
  </Paper>
);

export default SelectClientSection;
