import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import { FormattedMessage } from 'react-intl';
import EthDiamondPlain from '../../static/eth-diamond-plain.svg';
import { Text } from '../../pulsechain_components/Text';
import { Heading } from '../../pulsechain_components/Heading';

const Container = styled.div`
  background-color: ${(p: { theme: any }) => p.theme.gray.dark};
`;

const Card = styled.div`
  padding: 20px;
  border: 1px solid #323234;
  border-radius: 12px;
  width: 100%;
  margin: 16px;
  background: #202022;
  @media only screen and (max-width: ${p => p.theme.screenSizes.medium}) {
    margin: 0px;
    margin-top: 16px;
  }
`;

const SubContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  max-width: ${p => p.theme.screenSizes.largest};
  width: 100%;
  margin: 0 auto;
  padding: 0 120px;
  @media only screen and (max-width: ${p => p.theme.screenSizes.largest}) {
    padding: 0 60px;
  }
`;

// adds an opaque eth logo behind the text on small screen sizes
const ContentContainer = styled.div`
  @media only screen and (max-width: ${p => p.theme.screenSizes.medium}) {
    :before {
      content: ' ';
      display: block;
      position: absolute;
      right: 60px;
      top: 35px;
      width: 250px;
      height: 400px;
      z-index: 1;
      opacity: 0.15;
      background-image: url(${EthDiamondPlain});
      background-repeat: no-repeat;
      background-position: 50% 0;
      -ms-background-size: cover;
      -o-background-size: cover;
      -moz-background-size: cover;
      -webkit-background-size: cover;
      background-size: cover;
    }
  }
`;
export const Introduction = (): JSX.Element => {
  return (
    <Container>
      <SubContainer className="pb20 flex">
        <ContentContainer>
          <ScrollAnimation animateIn="fadeIn" animateOnce>
            <Heading level={2} size="medium" color="grayLight" margin="none">
              <FormattedMessage defaultMessage="Validators and PulseChain" />
            </Heading>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" animateOnce>
            <Card>
              <div>
                <Text>
                  <FormattedMessage
                    defaultMessage="This launchpad will help you become a PulseChain validator, so you can play
                      an active part in PulseChain's future. Validators are key to 
                      security, scalability, and sustainability of PulseChain."
                  />
                </Text>
                <Text className="mt20">
                  <FormattedMessage defaultMessage="By running a validator, you'll be responsible for securing the network and receive continuous payouts for actions that help the network reach consensus." />
                </Text>
                <Text className="mt20">
                  <FormattedMessage defaultMessage="PulseChain is fully secured by proof-of-stake validators. By running a validator, you'll be helping to secure the PulseChain network." />
                </Text>
              </div>
            </Card>
          </ScrollAnimation>
        </ContentContainer>
      </SubContainer>
    </Container>
  );
};
