import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import lighthouseBg from '../../../static/lighthouse-bg.png';
import {
  Hero,
  SectionTitle,
  ValidatorClientPageStyles,
} from '../ValidatorClientComponents';
import { PageTemplate } from '../../../pulsechain_components/PageTemplate';
import { Text } from '../../../pulsechain_components/Text';
import { Link } from '../../../pulsechain_components/Link';
import {
  IS_MAINNET,
  NETWORK_NAME,
  LIGHTHOUSE_INSTALLATION_URL,
} from '../../../utils/envVars';

const CodeSnippet = styled.div`
  padding: 10px;
  font-family: Courier, sans-serif;
  font-size: 1em;
  line-height: 1.3;
  color: #fff;
  background-color: #597ea3;
  border-radius: 6px;
  margin: 10px 0;

  code {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const LighthouseDetails = ({ shortened }: { shortened?: boolean }) => (
  <>
    <SectionTitle level={2} className="mb5">
      Lighthouse-Pulse
    </SectionTitle>
    <Text className="mt10">
      <FormattedMessage
        defaultMessage="A consensus client with a heavy focus on speed and security. Originally built by Sigma
        Prime, an information security and software engineering firm who have
        funded Lighthouse along with the Ethereum Foundation, Consensys, and
        private individuals."
      />
    </Text>
    <Link
      shouldOpenNewTab
      to="https://lighthouse-blog.sigmaprime.io/update-00.html"
      primary
      className="mt10"
    >
      <FormattedMessage defaultMessage="More on Lighthouse and Sigma Prime" />
    </Link>
    <SectionTitle level={2} className="mb5">
      <FormattedMessage defaultMessage="Language information" />
    </SectionTitle>
    <Text className="mt10">
      <FormattedMessage defaultMessage="Lighthouse-Pulse is built in Rust and offered under an Apache 2.0 License." />
    </Text>
    <section>
      <SectionTitle level={2} className="mb5">
        <FormattedMessage defaultMessage="Become a validator with Lighthouse-Pulse" />
      </SectionTitle>
      <Link shouldOpenNewTab primary to={LIGHTHOUSE_INSTALLATION_URL}>
        <FormattedMessage defaultMessage="Lighthouse-Pulse installation documentation" />
      </Link>
    </section>
    <>
      {!shortened && (
        <section>
          <SectionTitle level={2} className="mb5">
            <FormattedMessage defaultMessage="Key management" />
          </SectionTitle>

          <Text className="mt10">
            <FormattedMessage defaultMessage="Lighthouse-Pulse has dedicated tooling to make the transfer from the Launchpad as easy as possible." />
          </Text>

          <Text className="mt10">
            <FormattedMessage
              defaultMessage="Specifically, when starting your lighthouse-Pulse validator client, you
                can point it at the directory with your keystores and it will import
                your keystores and interactively ask for your password(s). If you do
                not supply a password, Lighthouse-Pulse will ask for it again every time
                it starts up."
            />
          </Text>
          <CodeSnippet>
            lighthouse account validator import --directory
            staking-deposit-cli/validator_keys{' '}
            {IS_MAINNET
              ? ''
              : `--network=${NETWORK_NAME.toLowerCase()
                  .split(' ')
                  .join('_')}`}
          </CodeSnippet>
          <Text className="mt10">
            <FormattedMessage defaultMessage="Once the process is complete, you should see the following:" />
          </Text>
          <CodeSnippet>
            <code>Successfully moved keystore.</code>
            <code>Successfully updated validator_definitions.yml.</code>
            <code>Successfully imported 1 validators.</code>
          </CodeSnippet>

          <SectionTitle level={3} className="mb5">
            <FormattedMessage defaultMessage="Running the validator client" />
          </SectionTitle>
          <Text className="mt10">
            <FormattedMessage
              defaultMessage="Now that the keys are imported, all that is left to do (assuming your beacon node is already
                running) is to run the validator client."
            />
          </Text>
          <CodeSnippet>
            <code>
              lighthouse vc{' '}
              {IS_MAINNET
                ? ''
                : `--network=${NETWORK_NAME.toLowerCase()
                    .split(' ')
                    .join('_')}`}
            </code>
          </CodeSnippet>

          <SectionTitle level={2} className="mb5">
            <FormattedMessage defaultMessage="Troubleshooting" />
          </SectionTitle>
          <Link
            shouldOpenNewTab
            primary
            to="https://lighthouse-book.sigmaprime.io/validator-import-launchpad.html"
          >
            <FormattedMessage defaultMessage="Importing from Launchpad documentation" />
          </Link>
        </section>
      )}
    </>
  </>
);

export const Lighthouse = () => {
  const { formatMessage } = useIntl();
  return (
    <PageTemplate
      title={formatMessage(
        { defaultMessage: 'Consensus Clients: {clientName}' },
        { clientName: 'Lighthouse-Pulse' }
      )}
    >
      <ValidatorClientPageStyles>
        <Hero imgSrc={lighthouseBg} />
        <LighthouseDetails />
        <section>
          <SectionTitle level={2} className="mb5">
            <FormattedMessage defaultMessage="Documentation" />
          </SectionTitle>
          <Link
            shouldOpenNewTab
            primary
            to="https://lighthouse-book.sigmaprime.io/"
          >
            <FormattedMessage defaultMessage="Documentation on running Lighthouse-Pulse" />
          </Link>
        </section>
      </ValidatorClientPageStyles>
    </PageTemplate>
  );
};
