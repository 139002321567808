import React from 'react';
import styled from 'styled-components';
import { Button as GrommetButton, ButtonProps } from 'grommet';

interface CustomButtonProps {
  className?: string;
  width?: number;
  fullWidth?: boolean;
  rainbow?: boolean;
  onClick?: () => any;
}

const calculateWidth = (p: { width?: number; fullWidth?: boolean }) => {
  if (p.width) {
    return `${p.width}px`;
  }
  if (p.fullWidth) {
    return '100%';
  }
};

const StyledButton = styled(GrommetButton)`
  display: block;
  padding: 15px;
  border-radius: 10px;
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  width: ${calculateWidth};
  font-size: 16px;
  letter-spacing: 1.5px;
  background: linear-gradient(45deg, rgb(0, 102, 34), rgb(0, 179, 60));
  color: white;
  border: ${p => `1px solid ${p.theme.gray.medium}`};
  &:hover {
    transition: transform 0.1s;
    transform: scale(1.02);
  }
`;

export const Button = (props: CustomButtonProps & ButtonProps) => {
  const { className } = props;
  return <StyledButton className={className} {...props} />;
};
